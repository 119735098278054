import React from 'react';
import { Link } from 'gatsby';
import { rhythm, scale } from '../utils/typography';
import './layout.css';

class Layout extends React.Component {
  render() {
    const { location, children } = this.props;
    const rootPath = `${__PATH_PREFIX__}/`;
    const siteTitle = 'awst.in';
    let header;

    if (location.pathname === rootPath) {
      header = (
        <h1
          style={{
            ...scale(1.5),
            marginBottom: rhythm(1.5),
            marginTop: 0,
          }}
        >
          <Link
            style={{
              fontFamily: 'Sanchez, sans-serif',
              boxShadow: 'none',
              textDecoration: 'none',
              color: 'inherit',
            }}
            to="/"
          >
            {siteTitle}
          </Link>
        </h1>
      );
    } else {
      header = (
        <h3
          style={{
            marginTop: 0,
            marginBottom: rhythm(-1),
          }}
        >
          <Link
            style={{
              boxShadow: 'none',
              textDecoration: 'none',
              color: 'inherit',
            }}
            to="/"
          >
            {siteTitle}
          </Link>
        </h3>
      );
    }
    return (
      <div
        id="content"
        style={{
          background: 'linear-gradient(#E0E0E0, #EFEFEF)',
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        {header}
        {children}
        {/* this.props.children({
              location: { pathname: this.props.location.pathname },
            }) */}
        {/* children( location: { pathname: this.props.location.pathname }) */}
      </div>
    );
  }
}

Layout.defaultProps = { location: { pathname: '/' } };

export default Layout;
