import React from 'react';

import profilePic from './roach.png';
import { rhythm } from '../utils/typography';

export default () => (
  <div
    style={{
      display: 'flex',
      marginBottom: rhythm(2.5),
    }}
  >
    <img
      src={profilePic}
      alt="EB"
      style={{
        marginRight: rhythm(1 / 2),
        marginBottom: 0,
        width: rhythm(2),
        height: rhythm(2),
      }}
    />
    <p>
      By 
      {' '}
      <strong>EB</strong>
      {' '}
in Austin, Texas.
    </p>
  </div>
);
